import { closeModal, isModalOpen, openModal } from '../../utils/modals_handler';
document.addEventListener('DOMContentLoaded', () => {
    const modalMenu = document.querySelector('.mainHeader__modalMenu');
    const menuButton = document.querySelector('.mainHeader__menuButton');
    const closeModalMenuButton = modalMenu.querySelector('button');
    // Открытия модалки с меню при клике на кнопку меню
    menuButton.addEventListener('click', (event) => {
        event.stopPropagation();
        openModal(modalMenu);
    });
    // Закрытие модалки с меню при клике на крестик
    closeModalMenuButton.addEventListener('click', () => {
        closeModal(modalMenu);
    });
    const modalContent = modalMenu.firstElementChild;
    // Закрытие модалки при клике вне модалки
    document.addEventListener('click', (e) => {
        const target = e.target;
        if (!modalContent.contains(target) && isModalOpen(modalMenu)) {
            closeModal(modalMenu);
        }
    });
});
